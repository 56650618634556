export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый',
        },
        color: 'green'
    },
    {
        num: 2,
        label: {
            en: 'Agreement',
            et: 'Kooskõlastamine',
            ru: 'Согласование',
        },
        color: 'orange'
    },
    {
        num: 3,
        label: {
            en: 'Waiting',
            et: 'Ootab',
            ru: 'Ожидание',
        },
        color: 'yellow'
    },
    {
        num: 5,
        label: {
            en: 'In work',
            et: 'Töös',
            ru: 'В работе',
        },
        color: 'purple'
    },
    {
        num: 7,
            label: {
            en: 'Completed',
            et: 'Valmis',
            ru: 'Выполнен',
        },
        color: 'blue'
    },
    // {
    //     num: 8,
    //     label: {
    //         en: 'Issued',
    //         et: 'Valmis',
    //         ru: 'Väljastatud',
    //     },
    //     color: 'blue'
    // },
    {
        num: 8,
        label: {
            en: 'Car issued',
            et: 'Väljastatud',
            ru: 'Авто выдан',
        },
        color: 'dark-blue'
    },
    {
        num: 9,
        label: {
            en: 'Paid part.',
            et: 'Os. tasutud',
            ru: 'Опл. част.',
        },
        color: 'blue'
    },
    {
        num: 10,
        label: {
            en: 'Paid',
            et: 'Tasutud',
            ru: 'Оплачен',
        },
        color: 'dark-blue'
    },
    {
        num: 12,
        label: {
            en: 'Canceled',
            et: 'Tühistatud',
            ru: 'Отменен',
        },
        color: 'red'
    },
]
//     en: [
//         {num: 1, label: 'New', color: 'green'},
//         {num: 3, label: 'Waiting', color: 'yellow'},
//         {num: 5, label: 'In work', color: 'black'},
//         {num: 7, label: 'Completed', color: 'blue'},
//         {num: 9, label: 'Paid partially', color: 'blue'},
//         {num: 10, label: 'Paid', color: 'dark-blue'},
//         {num: 12, label: 'Canceled', color: 'red'},
//     ],
//     et: [
//         {num: 1, label: 'Uus', color: 'green'},
//         {num: 3, label: 'Ootamine', color: 'yellow'},
//         {num: 5, label: 'Töös', color: 'black'},
//         {num: 7, label: 'Valmis', color: 'blue'},
//         {num: 9, label: 'Tasutud osaliselt', color: 'blue'},
//         {num: 10, label: 'Tasutud', color: 'dark-blue'},
//         {num: 12, label: 'Tühistatud', color: 'red'},
//     ],
//     ru: [
//         {num: 1, label: 'Новый', color: 'green'},
//         {num: 3, label: 'Ожидание', color: 'yellow'},
//         {num: 5, label: 'В работе', color: 'black'},
//         {num: 7, label: 'Выполнен', color: 'blue'},
//         {num: 9, label: 'Оплачен частично', color: 'blue'},
//         {num: 10, label: 'Оплачен', color: 'dark-blue'},
//         {num: 12, label: 'Отменен', color: 'red'},
//     ]
// }